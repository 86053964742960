<template>
  <b-row class="justify-content-center">
    <b-col cols="12" :md="isEdit ? 6 : 8">
      <vx-card>
        <vx-form
          ref="formRef"
          v-slot="{ loading }"
          :resource="formResource"
          @submit="handleSubmit"
        >
          <div class="mb-1 d-flex align-items-end">
            <vx-input
              v-model="formValue.code"
              :rules="rules.code"
              name="code"
              label="Code"
              class="mb-0 w-100"
            >
              <template v-if="!isEdit" #append>
                <vx-button
                  v-b-tooltip="'Generate code'"
                  :loading="loading"
                  variant="transparent"
                  size="sm"
                  icon="RefreshCwIcon"
                  @click="generateCode"
                />
              </template>
            </vx-input>
          </div>

          <vx-input
            v-model="formValue.message"
            :rules="rules.message"
            name="message"
            label="Message"
          />

          <vx-textarea
            v-model="formValue.notes"
            :rules="rules.notes"
            name="description"
            label="Description"
          />

          <div v-if="!isEdit">
            <vx-select
              v-model="formValue.bonus_type"
              :rules="rules.bonus_type"
              :options="bonusTypesOptions"
              :reduce="({ value }) => value"
              options-label="label"
              name="bonus_type"
              label="Bonus Type"
            />

            <vx-select-resource
              v-if="bonusResource"
              v-model="formValue.bonus_id"
              :rules="rules.bonus_id"
              :resource="bonusResource"
              :reduce="({ id }) => id"
              options-label="name"
              label="Bonus"
              name="bonus"
            />
          </div>

          <vx-input
            v-model="formValue.max_usage_count"
            :rules="rules.max_usage_count"
            name="max_usage_count"
            label="Max usage count"
          />

          <vx-date-range-picker
            v-model="formValue.active_range"
            :clearable="false"
            name="active_range"
            label="Activity range"
            type="datetime"
          />

          <vx-select
            v-if="isEdit"
            v-model="formValue.is_disabled"
            :options="statuses"
            :reduce="({ value }) => value"
            options-label="label"
            label="Status"
            name="status"
          />

          <vx-select-resource
            v-model="selectedTags"
            :resource="tagsResource"
            :reduce="({ id }) => id"
            options-label="name"
            label="Tags"
            name="tags"
            multiple
            deselect-from-dropdown
          />

          <div v-if="isEdit" class="mb-2">
            <div class="mb-1 d-flex flex-column">
              <div>
                <span class="font-weight-bold">Promocode Created At: </span>
                <span>{{ dateFormat(promocode.created_at) }}</span>
              </div>

              <div class="d-flex justify-content-between align-items-center">
                <span>
                  <span class="font-weight-bold">Promocode link: </span>
                  <b-link
                    :href="promocodeLink(promocode.code)"
                    target="_blank"
                  >
                    {{ promocodeLink(promocode.code) }}
                  </b-link>
                </span>

                <vx-button-copy :text-to-copy="promocodeLink(promocode.code)" />
              </div>

              <div>
                <span class="font-weight-bold">Creator: </span>
                <span>{{ admin }}</span>
              </div>
            </div>
          </div>

          <div v-if="!isEdit" class="mt-2">
            <vx-button
              v-b-tooltip="'Create this promocode and open Edit page'"
              :loading="loading"
              variant="primary"
              type="submit"
              class="mr-1 mt-1"
              @click="setEventInitiator(eventsMap.edit)"
            >
              Create and go to edit
            </vx-button>

            <vx-button
              v-b-tooltip="'Create this promocode and open Create promocode page'"
              :loading="loading"
              variant="primary"
              type="submit"
              class="mr-1 mt-1"
              @click="setEventInitiator(eventsMap.new)"
            >
              Create and go to empty new
            </vx-button>

            <vx-button
              v-b-tooltip="'Create this promocode and open Create promocode page with pre-filled data ' +
                '(Bonus type, Bonus, Max usage count, Activity range, Tags)'"
              :loading="loading"
              variant="primary"
              type="submit"
              class="mr-1 mt-1"
              @click="setEventInitiator(eventsMap.same)"
            >
              Create and go to the same new
            </vx-button>
          </div>
          <div v-else>
            <vx-button
              :loading="loading"
              variant="primary"
              type="submit"
            >
              Save
            </vx-button>
          </div>
        </vx-form>
      </vx-card>
    </b-col>
    <b-col v-if="isEdit" cols="12" md="6">
      <bonus-info :promocode="promocode" />
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BRow, BLink } from 'bootstrap-vue'
import { reactive, ref, computed } from '@vue/composition-api'
import { apiDefaultValidators, setValuesToForm } from '@/services/form'
import {
  VxForm,
  VxInput,
  VxTextarea,
  VxSelectResource,
  VxSelect,
  VxDateRangePicker
} from '@/components/form'
import { VxButton, VxButtonCopy } from '@/components/buttons'
import {
  bonuses,
  bonusTypesOptions,
  promocodes,
  tags,
  admins,
  promocodeLink,
  passDataToResource,
  useResource
} from '@/services/resources'
import VxCard from '@/components/VxCard'
import BonusInfo from '@/views/promocodes/BonusInfo'
import voucherCodeGenerator from 'voucher-code-generator'
import { formatToISODate, dateFormat } from '@/services/utils'
import { useToast } from '@/plugins/toastification'
import router, { buildRoute, path } from '@/router'

export default {
  name: 'PromocodeEditor',
  components: {
    BRow,
    BCol,
    BLink,
    BonusInfo,
    VxCard,
    VxForm,
    VxInput,
    VxSelect,
    VxSelectResource,
    VxTextarea,
    VxDateRangePicker,
    VxButton,
    VxButtonCopy
  },
  props: {
    dataResolverResponse: {
      type: Object,
      default: null
    }
  },

  setup ({ dataResolverResponse: promocode }) {
    const { id: promocodeId, creator_id } = promocode || {}
    const isEdit = !!promocode

    const tagsResource = tags.getAll
    const formRef = ref(null)
    const admin = ref(null)
    const selectedTags = ref([])
    const eventInitiator = ref(null)
    const initialForm = {
      code: '',
      message: '',
      notes: '',
      bonus_id: null,
      bonus_type: '',
      max_usage_count: null,
      is_disabled: false,
      active_range: [null, null]
    }
    const statuses = [
      {
        value: false,
        label: 'Enabled'
      },
      {
        value: true,
        label: 'Disabled'
      }
    ]
    const eventsMap = {
      edit: 'edit',
      new: 'new',
      same: 'same'
    }

    const frontToBackMapper = ({ active_range: [active_from, active_to] }) => {
      return {
        ...formValue,
        active_from: active_from && formatToISODate(active_from),
        active_to: active_to && formatToISODate(active_to)
      }
    }

    const backToFrontMapper = ({ active_from = null, active_to = null, tags = null, ...rest }) => {
      if (tags) selectedTags.value = tags.map(({ id }) => id)
      return {
        ...rest,
        active_range: [
          active_from && active_from,
          active_to && active_to
        ]
      }
    }

    const formResource = isEdit
      ? passDataToResource(promocodes.update, {
        frontToBackMapper,
        requestParams: { urlParams: { id: promocodeId } }
      })
      : passDataToResource(promocodes.create, {
        frontToBackMapper
      })

    const formValue = reactive({ ...initialForm })

    const bonusResource = computed(() => {
      if (!formValue.bonus_type) return null

      const [bonusTypeId] = formValue.bonus_type.split('-')
      return bonuses[bonusTypeId]
    })

    const rules = {
      code: {
        required: true,
        min: 2,
        max: apiDefaultValidators.maxTinyTextLength
      },
      message: {
        min: 2,
        max: apiDefaultValidators.maxTinyTextLength
      },
      notes: {
        min: 2,
        max: 10000
      },
      bonus_id: {
        required: true,
        numeric: true
      },
      bonus_type: {
        required: true
      },
      max_usage_count: {
        numeric: true,
        min_value: 0,
        max_value: 100000
      }
    }

    const getAdmin = async () => {
      const { can, callRequest } = useResource(admins.getOne)

      if (!can) return

      const [, res] = await callRequest({
        urlParams: { id: creator_id }
      })
      admin.value = `${res.data.first_name} ${res.data.last_name}`
    }

    const generateCode = () => {
      const [generatedCode] = voucherCodeGenerator.generate({
        length: 10,
        count: 1,
        charset: voucherCodeGenerator.charset('alphanumeric')
      })

      formValue.code = generatedCode
    }

    const setEventInitiator = (event) => {
      eventInitiator.value = event
    }

    const resetForm = () => {
      Object.assign(formValue, initialForm)
      selectedTags.value = []
      formRef.value.resetErrors()
    }

    const mapSavedFields = () => {
      const savedState = {
        ...initialForm,
        bonus_id: formValue.bonus_id,
        bonus_type: formValue.bonus_type,
        max_usage_count: formValue.max_usage_count,
        active_range: formValue.active_range
      }

      setValuesToForm(savedState, formValue)
      formRef.value.resetErrors()
    }

    const processAction = (id) => {
      switch (eventInitiator.value) {
        case eventsMap.edit:
          return router.push(buildRoute(path.promocodeDetails, { params: { id } }))
        case eventsMap.new:
          return resetForm()
        case eventsMap.same:
          return mapSavedFields()
      }
    }

    // TODO: add Can detection on select Tag action (for correct work with permission)
    const updateTags = async (id) => {
      const payload = {
        urlParams: { id },
        data: { tags: selectedTags.value }
      }

      const { can: canUpdateTags, callRequest } = useResource(promocodes.updateTags)

      if (!canUpdateTags) return

      await callRequest(payload)
    }

    const handleSubmit = async ([err, res]) => {
      if (err) return

      const id = isEdit ? promocodeId : res.data.id

      await updateTags(id)

      const status = isEdit ? 'updated' : 'created'
      const toast = useToast()
      toast.success(`Promocode with id: ${id} has been successfully ${status}`)

      if (!isEdit) await processAction(id)
    }

    if (isEdit) {
      setValuesToForm(backToFrontMapper(promocode), formValue)
      getAdmin()
    }

    return {
      formRef,

      dateFormat,
      promocodeLink,

      bonusTypesOptions,
      bonusResource,

      statuses,
      admin,
      promocode,

      tagsResource,
      selectedTags,

      formResource,
      formValue,
      rules,
      isEdit,
      handleSubmit,

      generateCode,

      eventsMap,
      eventInitiator,
      setEventInitiator
    }
  }
}
</script>
