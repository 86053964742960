var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"cols":"12","md":_vm.isEdit ? 6 : 8}},[_c('vx-card',[_c('vx-form',{ref:"formRef",attrs:{"resource":_vm.formResource},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('div',{staticClass:"mb-1 d-flex align-items-end"},[_c('vx-input',{staticClass:"mb-0 w-100",attrs:{"rules":_vm.rules.code,"name":"code","label":"Code"},scopedSlots:_vm._u([(!_vm.isEdit)?{key:"append",fn:function(){return [_c('vx-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Generate code'),expression:"'Generate code'"}],attrs:{"loading":loading,"variant":"transparent","size":"sm","icon":"RefreshCwIcon"},on:{"click":_vm.generateCode}})]},proxy:true}:null],null,true),model:{value:(_vm.formValue.code),callback:function ($$v) {_vm.$set(_vm.formValue, "code", $$v)},expression:"formValue.code"}})],1),_c('vx-input',{attrs:{"rules":_vm.rules.message,"name":"message","label":"Message"},model:{value:(_vm.formValue.message),callback:function ($$v) {_vm.$set(_vm.formValue, "message", $$v)},expression:"formValue.message"}}),_c('vx-textarea',{attrs:{"rules":_vm.rules.notes,"name":"description","label":"Description"},model:{value:(_vm.formValue.notes),callback:function ($$v) {_vm.$set(_vm.formValue, "notes", $$v)},expression:"formValue.notes"}}),(!_vm.isEdit)?_c('div',[_c('vx-select',{attrs:{"rules":_vm.rules.bonus_type,"options":_vm.bonusTypesOptions,"reduce":function (ref) {
              var value = ref.value;

              return value;
},"options-label":"label","name":"bonus_type","label":"Bonus Type"},model:{value:(_vm.formValue.bonus_type),callback:function ($$v) {_vm.$set(_vm.formValue, "bonus_type", $$v)},expression:"formValue.bonus_type"}}),(_vm.bonusResource)?_c('vx-select-resource',{attrs:{"rules":_vm.rules.bonus_id,"resource":_vm.bonusResource,"reduce":function (ref) {
              var id = ref.id;

              return id;
},"options-label":"name","label":"Bonus","name":"bonus"},model:{value:(_vm.formValue.bonus_id),callback:function ($$v) {_vm.$set(_vm.formValue, "bonus_id", $$v)},expression:"formValue.bonus_id"}}):_vm._e()],1):_vm._e(),_c('vx-input',{attrs:{"rules":_vm.rules.max_usage_count,"name":"max_usage_count","label":"Max usage count"},model:{value:(_vm.formValue.max_usage_count),callback:function ($$v) {_vm.$set(_vm.formValue, "max_usage_count", $$v)},expression:"formValue.max_usage_count"}}),_c('vx-date-range-picker',{attrs:{"clearable":false,"name":"active_range","label":"Activity range","type":"datetime"},model:{value:(_vm.formValue.active_range),callback:function ($$v) {_vm.$set(_vm.formValue, "active_range", $$v)},expression:"formValue.active_range"}}),(_vm.isEdit)?_c('vx-select',{attrs:{"options":_vm.statuses,"reduce":function (ref) {
              var value = ref.value;

              return value;
},"options-label":"label","label":"Status","name":"status"},model:{value:(_vm.formValue.is_disabled),callback:function ($$v) {_vm.$set(_vm.formValue, "is_disabled", $$v)},expression:"formValue.is_disabled"}}):_vm._e(),_c('vx-select-resource',{attrs:{"resource":_vm.tagsResource,"reduce":function (ref) {
              var id = ref.id;

              return id;
},"options-label":"name","label":"Tags","name":"tags","multiple":"","deselect-from-dropdown":""},model:{value:(_vm.selectedTags),callback:function ($$v) {_vm.selectedTags=$$v},expression:"selectedTags"}}),(_vm.isEdit)?_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"mb-1 d-flex flex-column"},[_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Promocode Created At: ")]),_c('span',[_vm._v(_vm._s(_vm.dateFormat(_vm.promocode.created_at)))])]),_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Promocode link: ")]),_c('b-link',{attrs:{"href":_vm.promocodeLink(_vm.promocode.code),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.promocodeLink(_vm.promocode.code))+" ")])],1),_c('vx-button-copy',{attrs:{"text-to-copy":_vm.promocodeLink(_vm.promocode.code)}})],1),_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Creator: ")]),_c('span',[_vm._v(_vm._s(_vm.admin))])])])]):_vm._e(),(!_vm.isEdit)?_c('div',{staticClass:"mt-2"},[_c('vx-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Create this promocode and open Edit page'),expression:"'Create this promocode and open Edit page'"}],staticClass:"mr-1 mt-1",attrs:{"loading":loading,"variant":"primary","type":"submit"},on:{"click":function($event){return _vm.setEventInitiator(_vm.eventsMap.edit)}}},[_vm._v(" Create and go to edit ")]),_c('vx-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Create this promocode and open Create promocode page'),expression:"'Create this promocode and open Create promocode page'"}],staticClass:"mr-1 mt-1",attrs:{"loading":loading,"variant":"primary","type":"submit"},on:{"click":function($event){return _vm.setEventInitiator(_vm.eventsMap.new)}}},[_vm._v(" Create and go to empty new ")]),_c('vx-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Create this promocode and open Create promocode page with pre-filled data ' +
              '(Bonus type, Bonus, Max usage count, Activity range, Tags)'),expression:"'Create this promocode and open Create promocode page with pre-filled data ' +\n              '(Bonus type, Bonus, Max usage count, Activity range, Tags)'"}],staticClass:"mr-1 mt-1",attrs:{"loading":loading,"variant":"primary","type":"submit"},on:{"click":function($event){return _vm.setEventInitiator(_vm.eventsMap.same)}}},[_vm._v(" Create and go to the same new ")])],1):_c('div',[_c('vx-button',{attrs:{"loading":loading,"variant":"primary","type":"submit"}},[_vm._v(" Save ")])],1)]}}])})],1)],1),(_vm.isEdit)?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('bonus-info',{attrs:{"promocode":_vm.promocode}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }