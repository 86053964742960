<template>
  <vx-table
    ref="tableRef"
    :resource="resource"
    :columns="columns"
    :data-mapper="dataMapper"
    stacked
  >
    <template #title>
      Bonus
    </template>
  </vx-table>
</template>

<script>
import { VxTable, VxCellLink, VxCellDate, VxCellMoney } from '@/components/table'
import { buildRoute } from '@/router'
import { bonuses, bonusesTypes, bonusTypes, passDataToResource } from '@/services/resources'
import { ref } from '@vue/composition-api'
import {
  addPercentSign,
  moneyFormat,
  secondsToDays,
  secondsToTime
} from '@/services/utils'
import { bonusUrls } from '@/views/bonuses/bonusesService'

export default {
  name: 'BonusInfo',
  components: {
    VxCellLink,
    VxTable,
    VxCellDate,
    VxCellMoney
  },
  props: {
    promocode: {
      type: Object,
      default: () => ({})
    }
  },
  setup (props) {
    const { bonus_type, bonus_id, totals } = props.promocode
    const bonus = ref({})
    const bonusType = bonus_type.split('-')[0]

    const resource = passDataToResource(bonuses.bonusByIdWithType, {
      requestParams: {
        urlParams: {
          type: bonusType,
          id: bonus_id
        }
      }
    })

    const dataMapper = (values) => {
      bonus.value = values
      return [values]
    }

    const refillBonusDescription = () => {
      const { type, value, currency_code, min_refill_amount } = bonus.value || {}

      const formatValue = type === bonusesTypes.fixed
        ? moneyFormat({ amount: value, currency: currency_code })
        : addPercentSign(value)

      return `<b>${formatValue}</b> for <b>${moneyFormat(min_refill_amount)}</b> min refill sum`
    }

    const defaultColumns = [
      { key: 'bonus_type' },
      {
        key: 'name',
        component: VxCellLink,
        tdAttr: (value, key, { bonus_type }) => ({
          label: value,
          href: buildRoute(bonusUrls[bonus_type], { query: { id: bonus_id } })
        })
      }
    ]

    const refillColumns = [
      ...defaultColumns,
      {
        key: 'totals',
        label: 'Usage count',
        formatter: () => totals
      },
      { key: 'max_usage_count' },
      { key: 'min_refill_amount', component: VxCellMoney },
      {
        key: 'description',
        formatter: (value) => {
          return value || refillBonusDescription()
        }
      },
      {
        key: 'lifetime',
        label: 'Lifetime',
        formatter: (value) => {
          return value
            ? `Days: ${secondsToDays(value)} Time: ${secondsToTime(value)}`
            : '-'
        }
      },
      { key: 'created_at', component: VxCellDate }
    ]

    const moneyColumns = [
      ...defaultColumns,
      {
        key: 'description',
        formatter: (value, key, item) => {
          return value || moneyFormat(item.amount)
        }
      },
      { key: 'created_at', component: VxCellDate }
    ]

    const columnsMap = {
      [bonusTypes.money]: moneyColumns,
      [bonusTypes.refill]: refillColumns
    }

    return {
      resource,
      columns: columnsMap[bonus_type],
      dataMapper
    }
  }
}
</script>
